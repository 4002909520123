'use strict';

// Constructor
var Home = function() {
    var input = $('._input');

    input.each(function(){
        var $this = $(this);
        var dataValue = $this.data('value');

        $this.on('focus', function(){
            $this.attr('value', '');
        });

        $this.on('blur', function(){
            $this.attr('value', dataValue);
        });
    });
};

module.exports = Home;
